<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<template>
  <div class="relative">
    <!-- Navbar Dropdown 1 row -->
    <nav
      class="font-inter mx-auto h-auto w-full sticky top-0 z-20 bg-[#fff] mb-0"
      x-data="{isOpen: false, menuOne:false}"
    >
      <!-- CONTAINER -->
      <div
        class="flex max-w-7xl flex-col px-5 py-6 lg:flex-row lg:items-center lg:justify-between md:px-10 lg:py-4 mx-auto"
      >
        <a href="/" class="mb-4 text-2xl font-semibold md:text-3xl">
          {{ translateLanguage("深港跨境数据验证平台") }}
        </a>
        <div
          class="mt-14 flex flex-col space-y-8 lg:mt-0 lg:flex lg:flex-row lg:space-x-1 lg:space-y-0"
          x-bind:class="isOpen ? 'show' : 'hidden'"
        >
          <div
            class="hover:cursor-pointer font-inter font-medium rounded-lg lg:px-6 lg:py-4 lg:hover:bg-gray-50 lg:hover:text-gray-800"
            @click="scrollToAnchor('home')"
          >
            {{ translateLanguage("平台简介") }}
          </div>
          <div
            class="hover:cursor-pointer font-inter font-medium rounded-lg lg:px-6 lg:py-4 lg:hover:bg-gray-50 lg:hover:text-gray-800"
            @click="scrollToAnchor('introduction')"
          >
            {{ translateLanguage("功能介绍") }}
          </div>
          <div
            class="hover:cursor-pointer font-inter font-medium rounded-lg lg:px-6 lg:py-4 lg:hover:bg-gray-50 lg:hover:text-gray-800"
            @click="scrollToAnchor('verify')"
          >
            {{ translateLanguage("在线验证") }}
          </div>
          <div
            class="hover:cursor-pointer font-inter font-medium rounded-lg lg:px-6 lg:py-4 lg:hover:bg-gray-50 lg:hover:text-gray-800"
            @click="scrollToAnchor('serve')"
          >
            {{ translateLanguage("服务接入") }}
          </div>
          <div
            class="hover:cursor-pointer font-inter font-medium rounded-lg lg:px-6 lg:py-4 lg:hover:bg-gray-50 lg:hover:text-gray-800"
            @click="
              jumpTo(
                'https://vdi.express.cnix.cn/vdi/bhkycEntrance.html?appId=qe4ymh5u'
              )
            "
          >
            {{ translateLanguage("民生服务") }}
          </div>
          <div
            class="hover:cursor-pointer font-inter font-medium rounded-lg lg:px-6 lg:py-4 lg:hover:bg-gray-50 lg:hover:text-gray-800"
            @click="scrollToAnchor('post')"
          >
            {{ translateLanguage("其他资讯") }}
          </div>
          <!-- DROPDOWN -->
          <div class="relative flex flex-col">
            <div
              x-on:click.prevent="menuOne = !menuOne"
              class="hover:cursor-pointer flex flex-row text-[#000000] font-inter font-medium rounded-lg lg:px-6 lg:py-4 lg:hover:bg-gray-50 lg:hover:text-gray-800"
              x-bind:class="menuOne"
            >
              {{ translateLanguage("语言") }}
              <svg
                x-bind:class="menuOne ? 'rotate-180' : 'rotate-0' "
                class="fill-current transition"
                style="width: 24px; height: 24px"
                viewBox="0 0 24 24"
              >
                <path
                  d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
                />
              </svg>
            </div>
            <!-- DROP DOWN MENU ONE -->
            <div
              class="lg:px-75 z-50 flex w-full lg:w-[300px] flex-col rounded-lg bg-gray-50 border px-5 py-5 lg:absolute lg:top-20 lg:flex-row lg:flex-wrap lg:py-7 lg:shadow-[0_16px_64px_-15px_rgba(45,96,173,0.15)]"
              x-show="menuOne"
              x-on:click.outside="menuOne = false"
              x-transition:enter="transition ease-out duration-200"
              x-transition:enter-start="opacity-0 translate-y-10"
              x-transition:enter-end="opacity-100 translate-y-0"
              x-transition:leave="transition ease-in duration-200"
              x-transition:leave-start="opacity-100 translate-y-0"
              x-transition:leave-end="opacity-0 translate-y-10"
            >
              <!-- ITEM -->
              <a
                href="/"
                class="hover:cursor-pointer flex grow flex-col rounded-lg px-5 py-4 lg:basis-[248px] xl:px-8"
                @click.stop="changeLanguage('zh_HK')"
              >
                <div class="text-black font-inter text-lg font-medium">
                  繁体
                </div>
              </a>
              <a
                href="/"
                class="hover:cursor-pointer flex grow flex-col rounded-lg px-5 py-4 lg:basis-[248px] xl:px-8"
                @click.stop="changeLanguage('zh_CN')"
              >
                <div class="text-black font-inter text-lg font-medium">
                  简体
                </div>
              </a>
            </div>
          </div>
        </div>
        <a
          href="#"
          class="absolute right-5 lg:hidden"
          x-on:click.prevent="isOpen = !isOpen"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.75 12H20.25"
              stroke="#160042"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3.75 6H20.25"
              stroke="#160042"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3.75 18H20.25"
              stroke="#160042"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
      </div>
    </nav>
    <section id="home">
      <!-- Container -->
      <div
        class="mx-auto w-full max-w-7xl px-5 py-12 md:px-10 md:py-16 lg:py-20 pt-0 md:pt-0 lg:pt-0 pl-0 md:pl-0 lg:pl-0 pr-0 md:pr-0 lg:pr-0 pb-0 md:pb-0 lg:pb-0"
      >
        <!-- Heading Content -->
        <div class="mb-10">
          <swiper-container
            navigation="true"
            pagination="true"
            :autoplay="true"
            :controller="true"
          >
            <swiper-slide v-for="(item, index) in bannerList" :key="index">
              <img
                class="inline-block h-auto w-full rounded-lg object-cover lg:h-[720px]"
                :src="
                  '/upload' +
                  (item.thumbnail && item.thumbnail.length >= 0
                    ? item.thumbnail[0]
                    : '')
                "
                alt=""
              />
            </swiper-slide>
          </swiper-container>
        </div>
      </div>
    </section>
    <!-- <section>
      <div class="mx-auto w-full max-w-7xl px-5 pb-0 md:px-10">
        <div class="mb-0">
          <img
            class="inline-block h-auto w-full rounded-lg object-cover lg:h-[568px]"
            src="/images/desc.png"
            alt=""
          />
        </div>
      </div>
    </section> -->
    <section>
      <div
        class="mx-auto w-full max-w-7xl px-5 pb-8 md:px-10 md:pb-8 lg:pb-8"
        data-aos="fade-up"
        data-aos-duration="900"
      >
        <div class="mx-auto w-full max-w-3xl text-center">
          <h2 class="text-2xl font-semibold md:text-5xl">
            {{ translateLanguage("新型数字化跨境服务基础设施") }}
          </h2>
        </div>
        <div class="gap-8 md:flex-row-reverse lg:gap-12">
          <div class="pt-6 text-center">
            {{
              translateLanguage(
                "深港跨境数据验证平台致力于为深港两地居民跨境工作、生活及企业拓展业务提供便利条件，促进数据要素安全、合规、高效流通，为助力粤港澳大湾区深度融合和高质量发展提供有力支撑。"
              )
            }}
          </div>
        </div>
      </div>
    </section>
    <section id="introduction">
      <!-- Container -->
      <div
        class="mx-auto w-full max-w-7xl px-5 pb-8 md:px-10"
        data-aos="fade-up"
        data-aos-duration="900"
      >
        <!-- Heading Div -->
        <div class="mx-auto w-full max-w-3xl text-center">
          <h2
            class="mb-6 text-center text-xl font-bold md:mb-6 md:text-3xl lg:text-3xl"
          >
            {{ translateLanguage("功能介绍") }}
          </h2>
        </div>
        <!-- Content -->
        <div class="gap-8 md:flex-row-reverse lg:gap-12">
          <div class="pt-0">
            <p class="pt-0">
              {{
                translateLanguage(
                  "深港跨境数据验证平台基于国产安全可控开源区块链底层技术FISCO BCOS和分布式数据传输协议（DDTP）进行开发，运用区块链不可篡改且可追溯的技术优势，以哈希值跨境验证实现用户自主携带资料的可信验证。平台自身不做数据原文件的跨境传输和存储，仅基于哈希值为业务场景机构提供可信验证服务。"
                )
              }}
            </p>
            <p class="pt-6">
              {{
                translateLanguage(
                  "平台方案中，在两地平台运营方之间构建跨境区块链网络，为机构提供数据可信验证服务，平台本身不与用户产生直接联系。场景机构通过平台运营方提供的API接入平台，获取跨境数据验证服务。同时，用户是个人信息数据跨境传输的核心——基于个人数据可携带权，用户从数据提供者处下载个人信息数据，并自主传输数据至数据接收者，并对使用范围和使用目的等进行授权，平台并不传输或存储任何敏感个人数据。用户下载数据文件的同时，数据提供者使用平台提供的加密算法在本地对下载的文件加密，生成数据独有的哈希值（由源文件基于加密算法计算而来的一串字符串或数据凭证，可以理解为“数据的指纹”）并经用后授权后上传至深港跨境数据验证平台的区块链上。数据接收方接到用户自主提交的数据文件后，通过平台提供的同样的加密算法，对文件加密生成哈希值，经用户授权后与深港跨境数据验证平台区块链网络上数据提供者生成的哈希值比对验证，以确保数据未被篡改、真实有效。方案通过传验分离实现数据跨境传输，在满足跨境数据监管法规的同时，可安全、高效的实现跨境数据流通。"
                )
              }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- <section>
      <div
        class="mx-auto w-full max-w-7xl px-5 pb-12 md:px-10 md:pb-16 lg:pb-20"
        data-aos="fade-up"
        data-aos-duration="900"
      >
        <div class="mx-auto w-full max-w-3xl text-center">
          <h2 class="mb-8 text-center text-xl font-bold md:mb-14 md:text-2xl">
            {{ translateLanguage("产品解决方案示意图") }}
          </h2>
        </div>
        <div class="gap-8 md:flex-row-reverse lg:gap-12">
          <div>
            <img src="/images/function-1.png" alt="" srcset="" />
          </div>
        </div>
      </div>
    </section> -->
    <section>
      <div
        class="mx-auto w-full max-w-7xl px-5 pb-6 md:px-10"
        data-aos="fade-up"
        data-aos-duration="900"
      >
        <div class="mx-auto w-full max-w-3xl text-center">
          <h2
            class="mb-6 text-center text-xl font-bold md:mb-6 md:text-3xl lg:text-3xl"
          >
            {{ translateLanguage("适用场景") }}
          </h2>
        </div>
        <div class="gap-8 md:flex-row-reverse lg:gap-12">
          <div class="pt-0">
            <p class="pt-0">
              {{
                translateLanguage(
                  "平台适用于全行业场景领域的跨境数据验证，例如：金融行业中，包括但不限于跨境开户数字化、跨境征信报告核验、跨境资产证明、跨境信用卡提额征信、跨境保险理赔、跨境保单续保费、跨境商事服务、跨境供应链金融等；民生领域，包括但不限于跨境个人身份核验、跨境婚姻证明核验、跨境学历核验、跨境无犯罪记录证明验证、跨境个人税务验证、职业证照跨境验证等；医疗领域，包括但不限于病历报告跨境验证、跨境医疗报销等；交通领域，包括但不限于跨境驾驶证信息验证、跨境物流信息验证、跨境交通信息验证等。"
                )
              }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <!-- Container -->
      <div class="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 pt-2 pb-8">
        <!-- Title -->
        <h2
          class="mb-6 text-center text-xl font-bold md:mb-6 md:text-3xl lg:text-3xl"
        >
          {{ translateLanguage("平台特色") }}
        </h2>
        <!-- List -->
        <ul class="grid gap-2 sm:grid-cols-2 md:grid-cols-5 md:gap-0">
          <li
            v-for="item in feature"
            :key="item.name"
            class="flex flex-col items-center gap-1 border-r-[1px] px-10 py-4 max-[767px]:p-8 max-[767px]:[border-right-style:none] pt-0 max-[767px]:pt-0 pb-0 md:pb-0 max-[767px]:pb-0"
          >
            <p
              class="text-sm font-semibold text-[#636262] md:text-sm lg:text-sm md:text-[#000] lg:text-[#000]"
            >
              <span
                :class="`bg-[url('./assets/bg.svg')] bg-cover bg-center px-4 text-white`"
              >
                {{ translateLanguage(item.name) }}
              </span>
              <!-- {{ translateLanguage(item.name) }} -->
            </p>
            <p class="text-center text-sm text-[#636262]">
              {{ translateLanguage(item.value) }}
            </p>
          </li>
        </ul>
      </div>
    </section>

    <section id="verify">
      <!-- Heading Div -->
      <div class="text-center">
        <h2
          class="mb-6 text-center text-xl font-bold md:mb-6 md:text-3xl lg:text-3xl"
        >
          {{ translateLanguage("在线验证") }}
        </h2>
        <p class="mx-auto mt-4 pb-4 max-w-[528px] text-[#636262] mb-0">
          {{
            translateLanguage(
              "此处提供面向公众的本地工具验证模式，可按下列步骤验真："
            )
          }}
        </p>
      </div>
      <!-- Contact Container -->
      <div class="mx-auto w-full max-w-7xl px-5 md:px-10 pb-8">
        <!-- Component -->
        <div
          class="text-center grid items-center gap-8 sm:gap-20 lg:grid-cols-2 lg:text-left"
        >
          <div
            class="order-1 lg:order-2 mx-auto max-w-md bg-[#f2f2f7] p-8 text-center rounded-3xl border w-full"
          >
            <div class="mb-4 w-full text-left">
              <div class="mb-4 flex flex-col gap-y-2">
                <div class="flex justify-center">
                  <div
                    class="hover:cursor-pointer"
                    :class="
                      activeTab === 0
                        ? 'mr-5 inline-block items-center bg-[#2f6ea2] px-6 py-3 text-center font-semibold text-white'
                        : 'mr-5 inline-block items-center border border-solid border-[#2f6ea2] bg-white px-6 py-3 text-center font-semibold text-[#2f6ea2]'
                    "
                    @click="activeTab = 0"
                  >
                    {{ translateLanguage("我是数据提供方") }}
                  </div>
                  <div
                    class="hover:cursor-pointer"
                    :class="
                      activeTab === 1
                        ? 'mr-5 inline-block items-center bg-[#2f6ea2] px-6 py-3 text-center font-semibold text-white'
                        : 'mr-5 inline-block items-center border border-solid border-[#2f6ea2] bg-white px-6 py-3 text-center font-semibold text-[#2f6ea2]'
                    "
                    @click="activeTab = 1"
                  >
                    {{ translateLanguage("我是数据验证方") }}
                  </div>
                </div>
              </div>
              <div class="mb-4 flex flex-col gap-y-2">
                <label for="name-2" class="mb-1 font-bold"
                  >{{ translateLanguage("1.下载离线工具,生成数据指纹") }}
                </label>
                <a
                  href="https://portal-assets.cdn.cnix.cn/202402/cbdv-simple-checksum-v0.0.1.exe"
                  target="_blank"
                  class="mr-5 inline-block items-center bg-[#2f6ea2] px-6 py-3 text-center font-semibold text-white"
                  >{{ translateLanguage("下载离线工具") }}</a
                >
              </div>
              <div class="mb-4 flex flex-col gap-y-2">
                <label for="name-2" class="mb-1 font-bold">
                  {{ translateLanguage("2.输入机构ID") }}
                </label>
                <NInput
                  v-model:value="form.weId"
                  type="text"
                  size="large"
                  class="w-full bg-[#FAFAFA] text-sm text-[#000]"
                  :placeholder="translateLanguage('请输入机构ID')"
                />
              </div>
              <div class="mb-4 flex flex-col gap-y-2">
                <label for="name-2" class="mb-1 font-bold">
                  {{ translateLanguage("3.输入密码") }}
                </label>
                <NInput
                  v-model:value="form.password"
                  type="password"
                  size="large"
                  class="w-full bg-[#FAFAFA] text-sm text-[#000]"
                  :placeholder="translateLanguage('输入密码')"
                />
              </div>
              <div class="mb-4 flex flex-col gap-y-2">
                <label for="name-2" class="mb-1 font-bold">
                  {{ translateLanguage("4.输入数据指纹") }}
                </label>
                <NInput
                  v-model:value="form.hashCode"
                  type="text"
                  size="large"
                  class="w-full bg-[#FAFAFA] text-sm text-[#000]"
                  :placeholder="translateLanguage('输入数据指纹')"
                />
              </div>
              <div class="mb-4 flex flex-col gap-y-2">
                <label for="name-2" class="mb-1 font-bold">
                  {{ translateLanguage("5.输入验证码(点击图片可换一张)") }}
                </label>
                <NInputGroup>
                  <NInput
                    v-model:value="form.captcha"
                    type="text"
                    size="large"
                    class="w-full bg-[#FAFAFA] text-sm text-[#000]"
                    :placeholder="translateLanguage('输入验证码')"
                  />
                  <div @click="refreshCode">
                    <SIdentify :identifyCode="identifyCode" />
                  </div>
                </NInputGroup>
              </div>
              <input
                type="submit"
                @click="handleSubmit"
                :value="translateLanguage(activeTab === 0 ? '上传' : '验证')"
                class="inline-block w-full cursor-pointer rounded-xl bg-[#2f6ea2] px-8 py-4 text-center font-semibold text-white no-underline"
              />
            </div>
          </div>
          <div
            class="order-2 lg:order-1 flex max-w-[496px] flex-1 flex-col items-start"
          >
            <a
              class="relative mb-0 flex gap-2 p-1 pb-0 w-full items-center"
              v-for="(item, index) in toolsStep"
              :key="item.name"
            >
              <div
                v-if="index < 5"
                class="flex h-6 w-6 flex-none flex-col items-center justify-center rounded-full bg-[#2f6ea2]"
              >
                <p class="font-bold text-white">{{ index + 1 }}</p>
              </div>
              <!-- <div
                v-else
                class="flex h-6 w-6 flex-none flex-col items-center justify-center rounded-full"
              ></div> -->
              <div class="ml-2 flex flex-col flex-1 gap-2">
                <h5
                  class="font-bold md:text-xl"
                  :class="index === 5 ? '' : 'text-left'"
                >
                  {{ translateLanguage(item.name) }}
                </h5>
                <p
                  class="text-[#636262]"
                  :class="index === 5 ? '' : 'text-left'"
                >
                  {{ translateLanguage(item.value) }}
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section id="serve">
      <div
        class="mx-auto w-full max-w-7xl px-5 pb-8 md:px-10"
        data-aos="fade-up"
        data-aos-duration="900"
      >
        <div class="text-center">
          <h2
            class="mb-6 text-center text-xl font-bold md:mb-6 md:text-3xl lg:text-3xl"
          >
            {{ translateLanguage("服务接入") }}
          </h2>
          <p class="pt-0 pb-8 mx-auto text-left">
            {{
              translateLanguage(
                "平台支持全行业的数据跨境验证场景，有意向获取深港跨境数据真实性核验能力的合法机构均可联系我们并申请接入平台。深港跨境数据验证平台由深圳与香港两地权威国企进行运营及运维。"
              )
            }}
          </p>
        </div>
        <div
          class="mx-auto grid max-w-[1040px] grid-cols-1 justify-items-center gap-5 sm:grid-cols-2 sm:justify-items-stretch md:grid-cols-3 md:gap-4 lg:gap-6"
        >
          <div
            v-for="item in address"
            :key="item.name"
            class="flex flex-col items-start justify-center gap-4 rounded-xl border border-solid border-[#636262] p-4"
          >
            <!-- [box-shadow:rgb(0,_0,_0)_6px_6px] -->
            <div class="mb-4 rounded-md bg-[#2f6ea2] text-white px-4 py-1.5">
              <p class="text-sm font-bold">
                {{ translateLanguage(item.base) }}
              </p>
            </div>
            <p class="mb-2 font-bold">{{ translateLanguage(item.name) }}</p>
            <p class="text-body text-[#636262]">
              <span class="font-bold text-black"
                >{{ translateLanguage("官网") }}：
              </span>
              {{ translateLanguage(item.官网) }}
            </p>
            <p class="text-body text-[#636262]">
              <span class="font-bold text-black"
                >{{ translateLanguage("电话") }}：
              </span>
              {{ translateLanguage(item.电话) }}
            </p>
            <p class="text-body text-[#636262]">
              <span class="font-bold text-black"
                >{{ translateLanguage("邮箱") }}：
              </span>
              {{ translateLanguage(item.邮箱) }}
            </p>
            <p class="text-body text-[#636262]">
              <span class="font-bold text-black"
                >{{ translateLanguage("地址") }}：
              </span>
              {{ translateLanguage(item.地址) }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- Section Blog -->
    <section class="bg-[#f2f2f7]" id="post">
      <!-- Container -->
      <div class="mx-auto w-full max-w-7xl px-5 py-8">
        <!-- Blog Title -->
        <!-- <h2 class="mb-6 text-center text-lg font-bold md:mb-6 md:text-2xl"></h2> -->
        <h2
          class="mb-6 text-center text-xl font-bold md:mb-6 md:text-3xl lg:text-3xl"
        >
          {{ translateLanguage("其他资讯") }}
        </h2>
        <!-- Blog Content -->
        <div class="mx-auto grid max-w-full gap-4">
          <div
            v-for="item in postList.slice(0, 3)"
            :key="item.id"
            @click="viewActicle(item.id)"
            class="hover:cursor-pointer flex items-center pb-8 [border-bottom:1px_solid_rgb(100,_112,_132)] w-full flex-row text-left"
          >
            <img
              :src="
                '/upload' +
                (item.thumbnail && item.thumbnail.length >= 0
                  ? item.thumbnail[0]
                  : '')
              "
              alt=""
              class="inline-block h-24 w-24 lg:h-40 lg:w-40 rounded-xl object-cover"
            />
            <div class="px-8 pt-0 w-full">
              <p class="mb-6 font-bold lg:text-xl">
                {{ translateLanguage(item.name) }}
              </p>
              <p class="text-sm">{{ item.time }}</p>
            </div>
          </div>
          <div
            @click="seeMore"
            class="hover:cursor-pointer w-48 m-auto inline-block rounded-xl bg-[#2f6ea2] px-8 py-4 text-center font-semibold text-white"
          >
            {{ translateLanguage("查看更多") }}
          </div>
        </div>
      </div>
    </section>
    <section class="overflow-hidden">
      <div
        class="w-screen flex-col bg-[#2f6ea2] px-6 py-20 lg:flex lg:px-10 xl:px-24"
      >
        <!-- TOP CONTAINER -->
        <div class="lg:flex lg:flex-row lg:justify-between">
          <!-- FOOTER LINKS -->
          <div
            class="flex grow flex-row flex-wrap lg:mx-10 lg:flex-nowrap lg:justify-center"
          >
            <!-- LINK BLOCK -->
            <div
              class="my-5 mr-6 flex max-w-[260px] grow basis-[100px] flex-col space-y-5 lg:mx-10 lg:mt-0"
            >
              <h2 class="font-inter font-medium text-white">
                {{ translateLanguage("指导单位") }}
              </h2>
              <div class="font-inter font-light text-white">
                {{ translateLanguage("深圳市互联网信息办公室") }}
              </div>
              <div class="font-inter font-light text-white">
                {{ translateLanguage("深圳市地方金融管理局") }}
              </div>
              <div class="font-inter font-light text-white">
                {{ translateLanguage("中国人民银行深圳市分行") }}
              </div>
              <div class="font-inter font-light text-white">
                {{ translateLanguage("香港金融管理局") }}
              </div>
            </div>
            <div
              class="my-5 mr-6 flex max-w-[260px] grow basis-[100px] flex-col space-y-5 lg:mx-10 lg:mt-0"
            >
              <h2 class="font-inter font-medium text-white">&#160;</h2>
              <div class="font-inter font-light text-white">
                {{ translateLanguage("深圳市人民政府港澳事务办公室") }}
              </div>
              <div class="font-inter font-light text-white">
                {{ translateLanguage("深圳市前海管理局") }}
              </div>
              <div class="font-inter font-light text-white">
                {{ translateLanguage("国家金融监督管理总局深圳监管局") }}
              </div>
            </div>
            <!-- LINK BLOCK -->
            <div
              class="my-5 mr-6 flex max-w-[260px] grow basis-[100px] flex-col space-y-5 lg:mx-10 lg:mt-0"
            >
              <h2 class="font-inter font-medium text-white">运营单位</h2>
              <div class="font-inter font-light text-white">
                {{ translateLanguage("国家（深圳·前海）新型互联网交换中心") }}
              </div>
              <div class="font-inter font-light text-white">
                {{ translateLanguage("深圳征信服务有限公司") }}
              </div>
              <div class="font-inter font-light text-white">
                {{ translateLanguage("香港科技园公司") }}
              </div>
            </div>
            <!-- LINK BLOCK -->
            <div
              class="my-5 mr-6 flex max-w-[260px] grow basis-[100px] flex-col space-y-5 lg:mx-10 lg:mt-0"
            >
              <h2 class="font-inter font-medium text-white">技术支持单位</h2>
              <div class="font-inter font-light text-white">
                深圳前海微众银行股份有限公司
              </div>
            </div>
          </div>
        </div>
        <!-- DIVIDER -->
        <div
          class="mx-auto my-12 w-full border border-[#E4E4E7] lg:my-20"
        ></div>
        <!-- COPYRIGHT TEXT -->
        <div>
          <p class="font-inter text-center text-sm text-white lg:mt-0">
            Copyright © 2020-2021 版权所有 深圳市前海新型互联网交换中心有限公司
            <a href="http://beian.miit.gov.cn/" target="_blank"
              >备案号：粤ICP备2021149937号</a
            >
            <a href="https://www.beian.gov.cn/portal/index" target="_blank"
              >公网安备号：粤公网安备44030502008895号</a
            >
          </p>
        </div>
      </div>
    </section>
  </div>
  <Modal
    ref="modal"
    :top="120"
    cancelText="取消"
    okText="确认"
    noticeText="知道了"
    :center="true"
    :visible="visible"
    @cancel="onCancel"
    @ok="onConfirm"
    @know="onKnow"
  />
</template>

<script lang="ts" setup>
import { ref, nextTick, onMounted } from "vue";
// import { defineComponent } from "vue";
import { videoPlay } from "vue3-video-play/lib/index";
import { register } from "swiper/element/bundle";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import "vue3-video-play/dist/style.css";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();
import { translateLanguage } from "@/utils/translate";
import { checkWeid, uploadHash, verifyHash } from "@/api";
import { isSHA256HexFormat, debounce } from "@/utils/utils";
import { NButton, NInput, NInputGroup } from "naive-ui";
import { md5 } from "js-md5";
import Modal from "@/components/ModalComponent.vue";
import SIdentify from "@/components/SidentifyComponent.vue";
const identifyCode = ref(""); //图形验证码
const identifyCodes = ref("1234567890");
import { postListGet, bannerListGet } from "@/api";
import { parseISO, format, compareDesc } from "date-fns";
onMounted(() => {
  identifyCode.value = "";
  makeCode(identifyCodes.value, 4);
});

// 生成随机数
const randomNum = (min: number, max: number): number => {
  max = max + 1;
  return Math.floor(Math.random() * (max - min) + min);
};
// 随机生成验证码字符串
const makeCode = (o: string, l: number) => {
  for (let i = 0; i < l; i++) {
    identifyCode.value += o[randomNum(0, o.length)] || 0;
  }
};
// 更新验证码
const refreshCode = () => {
  identifyCode.value = "";
  makeCode(identifyCodes.value, 4);
};
const options = ref({
  width: "100%", //播放器高度
  height: "400px", //播放器高度
  color: "#409eff", //主题色
  title: "", //视频名称
  src: "https://portal-assets.cdn.cnix.cn/202402/cbdv-siff-intro-1080p.mp4", //视频源
  muted: false, //静音
  webFullScreen: false,
  speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
  autoPlay: false, //自动播放
  loop: false, //循环播放
  mirror: false, //镜像画面
  ligthOff: false, //关灯模式
  volume: 0.3, //默认音量大小
  control: true, //是否显示控制
  controlBtns: [
    "audioTrack",
    "quality",
    "volume",
    "pip",
    "pageFullScreen",
    "fullScreen",
  ], //显示所有按钮,
});
const swiper = ["banner-1", "banner-2"];
const feature = [
  {
    name: "流程合规",
    value:
      "平台不跨境传输任何明文数据，同时不触碰、不留存任何数据原文，满足世界主流国家及地区跨境数据安全要求和隐私管理条例。",
  },
  {
    name: "数据可信",
    value: "利用区块链的共识特性和可追溯性，良好解决数据可信风险。",
  },
  {
    name: "跨境对等合作",
    value: "组成跨境多方共建的对等互信网络，实现公平、公正的跨境联盟治理。",
  },
  {
    name: "通用可扩展",
    value:
      "场景机构对接技术投入较小，在场景业务合规的前提下，可支持有跨境数据验证需求的全行业场景。",
  },
  {
    name: "网络效应",
    value:
      "平台基于区块链联盟链构建，具备跨机构、跨行业、跨场景的数据验证能力，具备很强的合作网络效应。",
  },
];
register();
const address = [
  {
    base: "深圳运营方",
    name: "国家（深圳·前海）新型互联网交换中心",
    官网: "www.cnix.cn",
    电话: "0755-88983323",
    邮箱: "marketing@cnix.cn",
    地址: "广东省深圳市前海深港创新中心A栋4楼",
  },
  {
    base: "深圳运营方",
    name: "深圳征信服务有限公司",
    官网: "www.szscredit.com",
    电话: "400 - 993 - 3056",
    邮箱: "service@szscredit.com",
    地址: "广东省深圳市福田区深业上城A座10层",
  },
  {
    base: "香港运营方",
    name: "香港科技园公司",
    官网: "www.hkstp.org",
    电话: "+852 2629 1818",
    邮箱: "enquiry.marketing@hkstp.org",
    地址: "香港科学园科技大道东5号5E大楼5楼",
  },
];
const toolsStep = [
  {
    name: "步骤一",
    value:
      "数据提供方下载离线工具至本地PC设备，并使用本地工具对数据文件生成专属的“数据指纹”。",
  },
  {
    name: "步骤二",
    value: "将数据指纹（包含数字和字母的64位字符串）复制粘贴至右侧对应填写框。",
  },
  {
    name: "步骤三",
    value: "用户自主将数据文件跨境转递至数据使用方",
  },
  {
    name: "步骤四",
    value:
      "数据使用方下载离线工具至本地PC设备，并使用本地工具对用户自主上传的数据文件生成专属的“数据指纹”。",
  },
  {
    name: "步骤五",
    value:
      "数据使用方将生成的“数据指纹，复制粘贴至右侧对应的填写框，获取验证结果。",
  },
  {
    name: "特别说明",
    value:
      "数据指纹为经过标准哈希算法加密生成的64位固定长度字符串哈希值，该哈希值不可反推获得数据原文。",
  },
];
const scrollToAnchor = (anchor: string) => {
  nextTick(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const element = document.querySelector(`#${anchor}`) as any;
    // console.log(element);
    if (element) {
      setTimeout(() => {
        let top = element.offsetTop - 200;
        console.log(top);

        window.scrollTo({
          top: top < 0 ? 0 : top,
          behavior: "smooth",
        });
        // handleTogglemenu();
      }, 0);
    }
  });
};
const jumpTo = (url: string) => {
  location.href = url;
};
const selectedLanguage = ref<string>("zh_CN");
const isDropdownOpen = ref<boolean>(false);
const changeLanguage = (language: string) => {
  selectedLanguage.value = language;
  isDropdownOpen.value = false;
  localStorage.setItem("language", language);
};
const form = ref({
  weId: "",
  password: "",
  hashCode: "",
  captcha: "",
});
const activeTab = ref(0);
const handleSubmit = debounce(async () => {
  console.log(form.value);
  const { weId, hashCode, captcha, password } = form.value;
  if (!weId) {
    showToast("请输入机构ID");
    return;
  }
  if (!password) {
    showToast("请输入密码");
    return;
  }
  if (!hashCode) {
    showToast("请输入数据指纹");
    return;
  }
  if (!captcha) {
    showToast("请输入验证码");
    return;
  }
  if (identifyCode.value !== captcha) {
    showToast("验证码错误");
    return;
  }

  if (!hashCode.trim()) {
    showToast(translateLanguage("请输入数据指纹"));
    return;
  }
  if (!isSHA256HexFormat(hashCode.trim())) {
    showToast(translateLanguage("数据指纹格式错误"));
    return;
  }

  if (captcha.trim() !== identifyCode.value) {
    showToast(translateLanguage("验证码错误"));
    refreshCode();
    return;
  }
  let _weId = weId;
  if (activeTab.value === 1) {
    let message = !_weId.trim() ? "请输入机构ID" : "机构ID不存在，存证失败";
    if (!_weId.trim()) {
      showToast(message);
      return;
    }
    const ret = await checkWeid(_weId, md5(password)).catch(() => {
      showToast(message);
      return;
    });
    if (!ret || (ret && !ret.respBody)) {
      if (ret?.errorCode === 42001) {
        showToast(translateLanguage("密码错误"), "error");
        return;
      }
      if (!ret?.errorCode) {
        showToast(translateLanguage(message), "error");
        return;
      }
      showToast(translateLanguage(ret.errorMessage), "error");
      return;
    }
  }

  if (activeTab.value === 0) {
    // 存hash值
    const ret = await uploadHash(_weId, hashCode, md5(password)).catch(() => {
      showToast(translateLanguage("保存失败"), "error");
      refreshCode();
    });
    if (!ret) {
      showToast(translateLanguage("保存失败"), "error");
      refreshCode();
      return;
    }
    if (ret && ret.errorMessage === "evidence already exists on chain") {
      showToast(translateLanguage("证据已经存在于链上"), "success");
      return;
    }
    showToast(translateLanguage("保存成功"), "success");
    return;
  }
  // else {
  //     _weId = ''
  // }

  const ret = await verifyHash(hashCode, _weId, md5(password)).catch(() => {
    showToast("验证失败", "error");
    refreshCode();
  });

  if (ret && ret.respBody) {
    showToast(translateLanguage("验证成功"), "success");
  } else {
    refreshCode();
    if (ret?.errorCode === 42001) {
      showToast(translateLanguage("密码错误"), "error");
      return;
    }
    if (!ret?.errorCode) {
      showToast(translateLanguage("验证失败"), "error");
      return;
    }
    showToast(translateLanguage(ret.errorMessage), "error");
  }
}, 800);
const modal = ref();
const loading = ref(false);
const visible = ref(false);
const showToast = (msg: string, type?: string) => {
  modal.value[type || "warning"]({
    title: translateLanguage("提示"),
    content: translateLanguage(msg),
  });
  visible.value = true;
};

function onCancel() {
  // “取消”按钮回调
  visible.value = false;
}

function onConfirm() {
  // “确定”按钮回调
  loading.value = true; // 开启加载状态
  setTimeout(() => {
    visible.value = false;
    loading.value = false;
  }, 500);
}

function onKnow() {
  visible.value = false;
}

const postList = ref<any[]>([]);
const getPostList = async () => {
  const res = await postListGet();
  postList.value = res.data
    .filter((item: any) => item.mode === "enabled")
    .map((item) => {
      return {
        ...item,
        time: format(parseISO(item.time), "yyyy-MM-dd HH:mm:ss"),
      };
    })
    .sort((a: any, b: any) => compareDesc(parseISO(a.time), parseISO(b.time)));
};
getPostList();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const bannerList = ref<any[]>([]);
const getBannerListGet = async () => {
  const res = await bannerListGet();
  bannerList.value = res.data;
  //   .filter((item: any) => item.mode === "enabled")
  //   .map((item) => {
  //     return {
  //       ...item,
  //       time: format(parseISO(item.time), "yyyy-MM-dd HH:mm:ss"),
  //     };
  //   })
  //   .sort((a: any, b: any) => compareDesc(parseISO(a.time), parseISO(b.time)));
};
getBannerListGet();

const seeMore = () => {
  router.push({ path: "/news" });
};
const viewActicle = (id: number | string) => {
  router.push({ name: "acticle", query: { id } });
};
</script>
